<template>
    <menu
        v-if="primaryMenu && primaryMenu.length"
        class="navigation-links-primary"
        :class="{
            'navigation-links-primary--inline': isInline,
        }"
    >
        <li
            v-for="(link, index) in primaryMenu"
            :key="`primary-menu-${index}`"
            :data-text="link.linkField.text"
        >
            <CraftLink :link="link.linkField">
                {{ link.linkField.text }}
            </CraftLink>
        </li>
    </menu>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';

const navigationStore = useNavigationStore();
const primaryMenu = computed(() => {
    return navigationStore.primaryMenu;
});

defineProps({
    isInline: {
        type: Boolean,
        default: false,
    }
});
</script>

<style lang="less" src="./NavigationLinksPrimary.less"></style>
