<template>
    <span
        class="navigation-icons"
        :data-navbar-fixed="isNavbarFixed"
    >
        <!--<BaseButton
            v-show="loggedIn"
            class="base-button--transparent-light"
            title="Notificaties"
            icon="alert"
            icon-position="center"
            data-dropdown="notifications"
            :aria-expanded="navigationStore.activeDropdown === 'notifications'"
            @click="navigationStore.openDropdown"
            @mouseenter="navigationStore.openDropdown"
        /> -->

        <BaseButton
            v-show="loggedIn"
            class="base-button--transparent-light"
            title="Service"
            icon="support"
            icon-position="center"
            data-dropdown="service"
            :aria-expanded="navigationStore.activeDropdown === 'service'"
            @click="navigationStore.openDropdown"
            @mouseenter="navigationStore.openDropdown"
        />

        <BaseButton 
            class="base-button--transparent-light"
            :element="NuxtLink"
            to="/zoeken"
            title="Zoeken"
            icon="search"
            icon-position="center"
        />

        <BaseButton
            v-show="loggedIn"
            class="base-button--transparent-light"
            :class="{
                'base-button--no-padding': !!user
            }"
            title="Mijn TLN"
            data-dropdown="profile"
            :aria-expanded="navigationStore.activeDropdown === 'profile'"
            aria-label="Mijn TLN"
            @click="navigationStore.openDropdown"
            @mouseenter="navigationStore.openDropdown"
        >
            <BaseAvatar
                v-if="user"
                :first-name="user.firstName"
                :last-name="user.lastName"
            />
        </BaseButton>

        <BaseButton
            v-show="!loggedIn"
            :element="NuxtLink"
            :to="{ name: 'mijn-tln.profiel' }"
            class="navigation-icons__mijn-tln"
            :class="{
                'base-button--transparent-light': !isNavbarFixed
            }"
            size="large"
            icon="mijn-tln"
        >
            Mijn TLN
        </BaseButton>
    </span>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
import { useNavigationStore } from '~/store/navigation';
const NuxtLink = resolveComponent('NuxtLink');

const navigationStore = useNavigationStore();
const authStore = useAuthStore();

defineProps({
    isNavbarFixed: {
        type: Boolean,
        default: false
    }
});

const loggedIn = computed(() => {
    return authStore.loggedIn;
});

const user = computed(() => {
    return authStore.user;
});
</script>

<style lang="less" src="./NavigationIcons.less"></style>
