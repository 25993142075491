<template>
    <menu
        v-if="secondaryMenu && secondaryMenu.length"
        class="navigation-links-secondary"
        :class="{
            'navigation-links-secondary--inline': isInline,
        }"
    >
        <ClientOnly>
            <InstallPwa />
        </ClientOnly>

        <li
            v-for="(link, index) in secondaryMenu"
            :key="`secondary-menu-${index}`"
        >
            <CraftLink :link="link.linkField">
                {{ link.linkField.text }}
            </CraftLink>
        </li>
    </menu>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';

const navigationStore = useNavigationStore();

const secondaryMenu = computed(() => {
    return navigationStore.secondaryMenu;
});

defineProps({
    isInline: {
        type: Boolean,
        default: false,
    }
});
</script>

<style lang="less" src="./NavigationLinksSecondary.less"></style>
