<template>
    <div class="hamburger-menu">
        <BaseButton
            :class="{ 'open': mainMenuOpen }"
            type="button"
            class="hamburger-menu__button base-button--transparent-light"
            icon-position="center"
            aria-label="Open menu"
            @click="navigationStore.toggleMainMenu()"
        >
            <i class="icon-menu">
                <span />
                <span />
                <span />
                <span />
                <span />
            </i>
        </BaseButton>
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useNavigationStore } from '~/store/navigation';

const navigationStore = useNavigationStore();
const { mainMenuOpen } = storeToRefs(navigationStore);
</script>

<style lang="less" src="./TheHamburgerMenu.less"></style>
