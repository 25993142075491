<template>
    <div class="layout">
        <!--
            Skiplinks link to landmarks in the website.
            Don't forget to add the IDs below to your custom elements.
            Keep in mind there can only be 1 <main> and 1 <footer> component in the context of the body
        -->
        <SkipLinks />

        <TheNavBar />
        <TheMenuOverlay />

        <slot />

        <TheFooter />
        <TheNavBarFixed />

        <!-- <NavigationDropdownNotifications /> -->
        <NavigationDropdownService />
        <NavigationDropdownProfile />
        <!-- <TheLoadingCurtain /> -->

        <ClientOnly>
            <LazyFollowDossiersModal />
            <LazyFilterModal />
            <LazyDossierUpdateFrequencyModal />
            <LazyAddEmployeeModal />
            <LazyOrganizationMutationModal />
            <LazyUserMutationModal />
        </ClientOnly>
    </div>
</template>

<script setup>
import GlobalDataQuery from '~/graphql/queries/global/GlobalData.graphql';
import DossierListQuery from '~/graphql/queries/DossierList.graphql';
import { useNavigationStore } from '~/store/navigation';
import { useDossierStore } from '~/store/dossiers';
import { useModalStore } from '~/store/modal';

const navigationStore = useNavigationStore();
const dossierStore = useDossierStore();
const modalStore = useModalStore();

const { data: dossierData } = await useLazyAsyncQuery({ query: DossierListQuery });

dossierStore.dossierList = toValue(dossierData)?.entries;

// General settings
const { data: globalData } = await useAsyncQuery({
    query: GlobalDataQuery
});

navigationStore.setGlobalData(toValue(globalData));

useHead({
    htmlAttrs: {
        lang: 'nl'
    },
    bodyAttrs: {
        'menu-open': navigationStore.isMainMenuOpen ? 'true' : 'false',
        'loading-active': navigationStore.isLoadingStateActive ? 'true' : 'false'
    },
    link: [
        {
            rel: 'icon',
            type: 'image/x-icon',
            href: '/favicons/tln.ico'
        }
    ],
});

const router = useRouter();

router.beforeEach(() => {
    navigationStore.enableLoadingState();
    navigationStore.closeDropdown();
});

router.afterEach(() => {
    navigationStore.disableLoadingState();
});

onMounted(() => {
    if (!localStorage.getItem('new-website-info-modal-opened')) {
        setTimeout(() => {
            localStorage.setItem('new-website-info-modal-opened', true);
            modalStore.open('new-website-info');
        }, 1000);
    }
});
</script>
