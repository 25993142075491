<template>
    <transition name="fade">
        <NavigationDropdown
            v-if="navigationStore.activeDropdown === 'service'"
            dropdown-class="navigation-dropdown--service"
        >
            <div class="navigation-dropdown--service__title">Hulp nodig?</div>
            <!-- <BaseButton
                v-if="navigationStore.communityUrl"
                class="base-button--transparent-dark"
                :element="NuxtLink"
                :to="navigationStore.communityUrl"
                size="large"
                target="_blank"
            >
                Raadpleeg de community
                <template #icon>
                    <BaseIcon icon="arrow-right" />
                </template>
            </BaseButton> -->
            <BaseButton
                class="base-button--transparent-dark"
                :element="NuxtLink"
                to="tel:0884567567"
                size="large"
            >
                Ledendesk: 088-4567567
                <template #icon>
                    <BaseIcon icon="phone" />
                </template>
            </BaseButton>
        </NavigationDropdown>
    </transition>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';
const NuxtLink = resolveComponent('NuxtLink');

const navigationStore = useNavigationStore();
</script>

<style lang="less" src="./NavigationDropdownService.less"></style>
