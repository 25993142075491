<template>
    <nav
        class="navbar-fixed"
        :data-visible="isVisible"
    >
        <div class="navbar-fixed__container">
            <NuxtLink
                class="navbar-fixed__logo"
                to="/"
            >
                <img
                    src="~/assets/img/logo.svg"
                    alt="Logo"
                    loading="eager"
                    fetchpriority="high"
                    width="50"
                    height="32"
                >
            </NuxtLink>

            <div
                class="navbar-fixed__menu"
                data-navbar-align
            >
                <NavigationLinksPrimary
                    :is-inline="true"
                />

                <NavigationLinksSecondary
                    v-if="!loggedIn"
                    :is-inline="true"
                />

                <NavigationIcons
                    :is-navbar-fixed="true"
                />
            </div>
        </div>
    </nav>
</template>

<script setup>
import { throttle } from 'lodash-es';
import { useAuthStore } from '~/store/auth';

const NuxtLink = resolveComponent('NuxtLink');

const authStore = useAuthStore();
const loggedIn = computed(() => {
    return authStore.loggedIn;
});

const isVisible = ref(false);
const visibilityOffset = 0;
const throttleDelay = 100;

const handleScroll = function() {
    const offset = getOffsetHeight() + visibilityOffset;

    if (window.scrollY > offset) {
        isVisible.value = true;
    } else {
        isVisible.value = false;
    }
};

function getOffsetHeight() {
    const $hero = document.querySelector('[data-hero]');

    return $hero?.offsetHeight || 250;
}

onMounted(() => {
    window.addEventListener('scroll', throttle(handleScroll, throttleDelay));
});

onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="less" src="./TheNavBarFixed.less"></style>
