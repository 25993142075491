<template>
    <FocusLoop
        class="navigation-dropdown"
        role="menu"
        :style="{
            top: `${position.top}px`,
            right: `${position.right}px`
        }"
        @mouseleave="navigationStore.closeDropdown()"
    >
        <div :class="dropdownClass">
            <slot name="default" />

            <div
                v-if="!!$slots.footer"
                class="navigation-dropdown__footer"
            >
                <slot name="footer" />
            </div>
        </div>

        <BaseButton
            class="navigation-dropdown__close base-button--transparent-dark"
            icon="close"
            icon-position="center"
            size="medium"
            sr-label="Sluit menu dropdown"
            @click="navigationStore.closeDropdown"
        />
    </FocusLoop>
</template>

<script setup>
import { useNavigationStore } from '~/store/navigation';
import { throttle } from 'lodash-es';
import { FocusLoop } from '@vue-a11y/focus-loop';
const navigationStore = useNavigationStore();

defineProps({
    dropdownClass: {
        type: String,
        default: null
    }
});

const position = ref({
    top: 0,
    right: 0
});

const handleResize = () => {
    position.value = calculatePosition();
    navigationStore.closeDropdown();
};

const handleClickedOutside = (event) => {
    if (!event.target.closest('.navigation-dropdown') && event.target !== navigationStore.activeDropdownTrigger) {
        navigationStore.closeDropdown();
    }
};

const throttledResizeHandler = throttle(handleResize, 500);
const throttledScrollHandler = throttle(handleResize, 500);

onBeforeMount(() => {
    position.value = calculatePosition();

    window.addEventListener('resize', throttledResizeHandler);
    window.addEventListener('scroll', throttledScrollHandler);
    window.addEventListener('click', handleClickedOutside);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', throttledResizeHandler);
    window.removeEventListener('scroll', throttledScrollHandler);
    window.removeEventListener('click', handleClickedOutside);
});

const calculatePosition = () => {
    // Set the right position of the dropdown to the right of the navbar
    const navbar = navigationStore.activeDropdownTrigger.closest('[data-navbar-align]');
    const navbarRect = navbar.getBoundingClientRect();
    const right = window.innerWidth - navbarRect.right;

    // Set the top position of the dropdown to the bottom of the trigger element
    const trigger = navigationStore.activeDropdownTrigger;
    const triggerRect = trigger.getBoundingClientRect();
    const top = triggerRect.top + trigger.offsetHeight + 20;

    return {
        top,
        right
    };
};
</script>

<style lang="less" src="./NavigationDropdown.less"></style>
