<template>
    <DelayHydration>
        <transition name="menu-overlay">
            <FocusLoop
                :is-visible="navigationStore.isMainMenuOpen"
                class="menu-overlay"
                role="dialog"
                :auto-focus="false"
            >
                <div class="menu-overlay__grid">
                    <div class="menu-overlay__body">
                        <div class="menu-overlay__main">
                            <NavigationLinksPrimary class="navigation-links-primary--list" />
                            <NavigationLinksSecondary class="navigation-links-secondary--list" />

                            <div class="menu-overlay__actions">
                                <BaseButton
                                    size="large"
                                    icon="mijn-tln"
                                    :element="NuxtLink"
                                    :to="{ name: 'mijn-tln.profiel' }"
                                >
                                    Mijn TLN
                                </BaseButton>
                            </div>
                        </div>
                    </div>

                    <div class="menu-overlay__footer">
                        <svg
                            class="menu-overlay__footer__triangle"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 390 25"
                            fill="none"
                        >
                            <path
                                d="M0 3.40949e-05L2.18557e-06 25L390 25L0 3.40949e-05Z"
                                fill="white"
                            />
                        </svg>

                        <div class="menu-overlay__footer__body">
                            <h4>Contact</h4>

                            <div>
                                <BaseButton
                                    class="base-button--tertiary"
                                    icon="mail"
                                    icon-position="after"
                                    element="a"
                                    href="mailto:info@tln.nl"
                                    size="large"
                                >
                                    info@tln.nl
                                </BaseButton>

                                <BaseButton
                                    class="base-button--tertiary"
                                    icon="phone"
                                    icon-position="after"
                                    element="a"
                                    href="tel:0884567567"
                                    size="large"
                                >
                                    088 4 567 567
                                </BaseButton>
                            </div>
                        </div>
                    </div>
                </div>
            </FocusLoop>
        </transition>
    </DelayHydration>
</template>

<script setup>
import { FocusLoop } from '@vue-a11y/focus-loop';
import { useNavigationStore } from '~/store/navigation';

const NuxtLink = resolveComponent('NuxtLink');

// Close the menu when changing routes
const navigationStore = useNavigationStore();
const route = useRoute();

watch(() => route.path, () => {
    navigationStore.closeMainMenu();
});
</script>

<style lang="less" src="./TheMenuOverlay.less"></style>
