<template>
    <nav class="navigation-bar">
        <div class="navigation-bar__secondary">
            <div class="navigation-bar__secondary-items">
                <NavigationLinksSecondary :is-inline="true" />

                <BaseButton
                    v-show="!loggedIn"
                    :element="NuxtLink"
                    :external="true"
                    to="/mijn-tln/profiel"
                    size="large"
                    icon="mijn-tln"
                >
                    Mijn TLN
                </BaseButton>
            </div>
        </div>

        <div class="navigation-bar__primary">
            <NuxtLink
                class="navigation-bar__logo"
                to="/"
            >
                <img
                    src="~/assets/img/logo.svg"
                    alt="Logo"
                    loading="eager"
                    fetchpriority="high"
                    width="70"
                    height="44"
                >
            </NuxtLink>

            <div
                class="navigation-bar__primary-menu"
                data-navbar-align
            >
                <NavigationLinksPrimary
                    :is-inline="true"
                />

                <NavigationIcons />

                <TheHamburgerMenu />
            </div>
        </div>
    </nav>
</template>

<script setup>
import { useAuthStore } from '~/store/auth';
const NuxtLink = resolveComponent('NuxtLink');

const authStore = useAuthStore();
const loggedIn = computed(() => {
    return authStore.loggedIn;
});
</script>

<style lang="less" src="./TheNavBar.less"></style>
